import React from 'react';
import "./DeadEnd.css";

function DeadEnd(){
    return (
        <div className="dead-end">
            <h1>404 PAGE NOT FOUND</h1>
        </div>
    )
}
export default DeadEnd;
